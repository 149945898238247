/*
// jei reikia, galime perrasyti core komponentus, pvz.
init_tooltips = function(context) {
	$('[data-toggle="tooltip"]', context).tooltip({placement:'bottom'});
}
*/

var marquee_init = false;
$(function () {
    // MOBILE MENU
    mobile_menu();
    page_editor_categories();
    init_marquee();
    init_wishlist2();
    init_nagotiations_form();

    $('.content_layout_checkout .summary-section .cart-summary-header').on('click', function(e) {
        e.preventDefault();

        $('.content_layout_checkout .summary-section').toggleClass('open');
        $('body').toggleClass('no-scroll-mob');
    });

    $('.content_layout_checkout .summary-section .group-header-wrp').on('click', function(e) {
        $(this).parent().toggleClass('open');
        $(this).parent().find('.group-body-wrp').slideToggle();
    });

    // sidebar close - remove overlay and sidebar
    $(document).on('click', '.overlay_temp, .close-sidebar', function() {
        if ($('#filtersPopup').length && $('#filtersPopup').hasClass('open')) {
            productFilter.reload();
        }
        $('.overlay_temp').remove();
        $('.sidebar-wrp, #quick_search, #navbarMobile').removeClass('open');
        $('.sidebar-wrp').find('.open').each(function() {
            $(this).removeClass('open');
        });
        $('body').removeClass('no-scroll');
    });

    // filters
    $(document).on('click', '#filtersPopup .widget-title', function() {
        $(this).parents('.widget').toggleClass('open');
    });
    $(document).on('click', '#filtersPopup .act-load-all', function(e) {
        e.preventDefault();
        $(this).parents('.widget').find('.hidden').removeClass('hidden');
        $(this).hide();
    });


    // country select popup
    $(document).on('click', '.act-open-project-select-popup:not(.clicked)', function() {
        $(this).addClass('clicked');
        $.ajax({
            type: 'GET',
            url: '?display=content_types/pages/project_select',
            success: function(data) {
                $(this).removeClass('clicked');
                $('body').append(data);
            }
        });
    });

    // run on window resize
    $(window).resize(function() {
        init_marquee();
        update_breadcrumb();
    });

    update_breadcrumb();
});

function mobile_menu() {
    $('#navbarMobile .dropdown').on('hide.bs.dropdown', function (e) {
        if (
            e.clickEvent
            && e.clickEvent.target.className != "nav-link"
            && e.clickEvent.target.className != "overlay_temp"
            && e.clickEvent.target.className != "dropdown-title-mob"
        ) {
            e.preventDefault();
        }
    });
    $('#navbarMobile .dropdown-title-mob').on('click', function() {
        $(this).parent().parent().find('.dropdown-toggle').trigger('click');
    });

    $('[data-mob-menu]').on('click', function() {
        let mobile_menu = $($(this).data('mob-menu'));

        $('body').addClass('no-scroll');
        $('#main-header .navbar-sticky').append('<div class="overlay_temp"></div>');
        mobile_menu.toggleClass('open');
    });

    $('.mobile-menu-close').on('click', function() {
        let mobile_menu = $($(this).data('menu-id'));

        $('.overlay_temp').remove();
        mobile_menu.removeClass('open');
        $('body').removeClass('no-scroll');
    });
}

function page_editor_categories() {
    $('.editor_type_categories').each(function() {
        let parent = $(this);

        parent.find('.categories-wrp ul a').on('mouseenter', function(e) {
            // e.stopImmediatePropagation();
            // e.stopPropagation();

            if (get_vw() > screen_sm_max) {
                let category_id = $(this).data('id');

                parent.find('.banner-block-wrp').each(function() {
                    let banner_block = $(this);

                    if (banner_block.find('div[data-category-id="'+ category_id +'"]').length) {
                        banner_block.find('> div').stop( true, true ).fadeOut();
                        banner_block.find('div[data-category-id="'+ category_id +'"]').stop( true, true ).fadeIn();
                    } else{
                        banner_block.find('> div').stop( true, true ).fadeOut();
                        banner_block.find('div.default-photo').stop( true, true ).fadeIn();
                    }
                });
            }
        });
    });
}

function init_wishlist2() {
    $(document).on('click', '.add_to_wishlist2', function(e) {
        e.preventDefault();
        var el = $(this);
        if (el.data('id') == "") {
            alert ($alert_message);
        } else {
            $.ajax({
                url: '?display=content_types/wishlist/update_wishlist',
                data: {entity_id: el.data('id'),entity_name: el.data('entity')},
                method: 'post',
                dataType: 'json',
                success: function (json) {
                    let w_elements = $('.add_to_wishlist2[data-id="'+ el.data('id') +'"][data-entity="'+ el.data('entity') +'"]');
                    let counter = w_elements.find('.counter').text();
                    counter = parseInt(counter);

                    if (json.status > 0) {
                        w_elements.addClass('active');
                        w_elements.attr('title', w_elements.data('del-title'));
                        w_elements.attr('data-bs-original-title', w_elements.data('del-title'));

                        w_elements.find('.counter').text(counter + 1);
                    } else if (json.status < 0) {
                        w_elements.removeClass('active');
                        w_elements.attr('title', w_elements.data('add-title'));
                        w_elements.attr('data-bs-original-title', w_elements.data('add-title'));

                        w_elements.find('.counter').text(counter - 1);
                    }
                    update_wishlist_info();
                }
            });
        }
    });
}

function init_marquee() {
    const pxPerSec = 50;
    const pxPerSecMob = 50;

    $(document).find('.marquee').each(function() {
        var id = $(this).attr('id');
        var containerElem = document.querySelector('#' + id + ' .inner-wrp');
        var contentElem = document.querySelector('#' + id + ' .inner-wrp ul');

        if (!marquee_init) {
            var contentCloneElem = contentElem.cloneNode(true);
            containerElem.appendChild(contentCloneElem);

            marquee_init = true;
        }

        var contentWidth = contentElem.clientWidth;

        if (get_vw() > screen_sm_max) {
            containerElem.style.animationDuration = `${contentWidth / pxPerSec}s`;
        } else {
            containerElem.style.animationDuration = `${contentWidth / pxPerSecMob}s`;
        }
    });
}

function init_nagotiations_form() {
    $(document).on('click', '.act-open-nagotiations-form', function(e) {
        e.preventDefault();

        let title = $(this).data('title');
        let data = {
            product_id : $(this).data('id')
        }

        ajaxnav({
            url: document.location.href,
            data: data,
            template: 'content_types/products/nagotiations_form',
            callback: function (html) {
                doModal(html, title);
            }
        });
    });
}